<template>
  <i18n-link
    :to="{ name: 'news-slug', params: { slug: data?.slug }, query: $route?.query }"
    :class="$style.root"
    :data-without-relation="!relations ? '' : null"
    :data-without-color="!colors ? '' : null"
  >
    <div :class="$style.datePosted">
      {{ includeLabel ? `${$t('labels.news')},` : '' }}
      <core-date-time-range-component
        v-if="data?.datePosted"
        :date-start="data?.datePosted"
      />
    </div>

    <div
      :class="$style.content"
      :style="cssColors"
      v-animated-underline-mouse-events
    >
      <div
        :class="$style.title"
      >
        {{ titleTruncated }}
      </div>

      <div
        v-if="relations"
        :class="$style.relations"
      >
        {{ relations?.trim() }}
      </div>

      <div
        v-if="coverImage?.formats"
        :class="$style.image"
      >
        <core-image-component
          :image="coverImage"
        />
      </div>

      <core-text-component
        v-if="firstParagraph"
        :string="firstParagraph"
        :class="$style.firstParagraph"
      />
    </div>
  </i18n-link>
</template>

<script lang="ts" setup>
import { uniq } from 'lodash-es'

const props = defineProps({
  data: {
    type: Object
  },
  includeLabel: {
    type: Boolean,
    default: false
  }
})

const biennialEntries = props.data?.biennialEntries || []
const relations = biennialEntries?.map(i => i?.title?.trim())?.filter(Boolean)?.join(', ') || biennialEntries?.map(i => i?.title)?.filter(Boolean)?.join(', ')
const coverImage = props.data?.coverImage
const colors = biennialEntries?.find(i => i?.colors)?.colors || biennialEntries?.find(i => i?.colors)?.colors
const cssColors = getCssColors(colors)
const titleTruncated = baseTruncateString(props?.data?.title, 8)
const firstParagraph = baseStrapiGetFirstParagraphFromBlocks(props?.data?.blocks, 24)
</script>

<style module lang="scss">
.root {
  composes: reset-link from global;
  --button-focus--box-shadow--color: var(--color--primary--default);

  width: var(--width--thumbnail--2-4);
  max-width: var(--max-width--thumbnail);

  display: block;
  overflow: hidden;
}

.root:focus-within .image {
  outline: var(--outline--focus);
}

.root[data-without-color] {
  padding-top: 0;
  padding-bottom: 0;
}

.title {
  composes: animated-underline from global;
}

.datePosted {
  composes: font-size-small from global;
  margin-bottom: var(--unit--spacer);
  color: var(--color--secondary);
  margin-left: var(--unit--horizontal);
  margin-right: var(--unit--horizontal);
}

.content {
  background-color: var(--color--background);
  color: var(--color--primary);
  padding: var(--unit--vertical) var(--unit--horizontal);
}

.root[data-without-color] .content {
  padding-top: 0;
  padding-bottom: 0;
}

.title {
  composes: font-size-medium from global;
}

.image {
  display: block;

  margin-top: var(--unit--vertical);

  transition: transform var(--transition-duration--default);
}

.image [data-orientation="landscape"] {
  --base--image--aspect-ratio: 13 / 9;
  --base--image--object-fit: cover;
}

.image [data-orientation="square"] {
  --base--image--aspect-ratio: 1;
  --base--image--object-fit: cover;
}

.image [data-orientation="portrait"] {
  --base--image--aspect-ratio: 9 / 12;
  --base--image--object-fit: cover;
}

.firstParagraph {
  margin-top: var(--unit--vertical);
}
</style>
